import React from "react"
import propTypes from "prop-types"

import classnames from "classnames"
import * as styles from "./styles"
import logoFP from "./../../../../../images/fp-logo-white.svg"
import dataAttributes from "../../../../../utils/dataAttributes"
import { GatsbyImage } from "gatsby-plugin-image"

const Partners = ({ partner, isAfterEvent, isVideo }) => {
    const {
        title,
        acf: { link, smalllogo },
    } = partner

    return (
        <div
            className={classnames(styles.Partners, {
                [styles.PartnersIsAfterEvent]: isAfterEvent,
                [styles.PartnersIsVideo]: isVideo,
            })}
        >
            <div>Powered by:</div>
            <a
                {...dataAttributes("banner-fp-logo", "FP logo")}
                href="https://future-processing.com"
                target="_blank"
                rel="noopener noreferrer"
            >
                <img src={logoFP} alt="Future Processing" />
            </a>
            <a
                {...dataAttributes("banner-fp-partner-logo", title)}
                href={link}
                target="_blank"
                rel="noopener noreferrer"
            >
                <GatsbyImage
                    image={smalllogo.localFile.childImageSharp.gatsbyImageData}
                />
            </a>
        </div>
    )
}

Partners.propTypes = {
    partner: propTypes.object,
}

export default Partners
