import React from "react"
import PropTypes from "prop-types"
import { GatsbyImage } from "gatsby-plugin-image"

import dataAttributes from "../../../utils/dataAttributes"

import * as styles from "./styles"
import { H1 } from "../../ui/headers/Headers"

const OurPartners = ({ props: { header, subheader, partners } }) => {
    return (
        <section className={styles.OurPartners}>
            <div className="row l-Inner">
                <div className="xsmall-12">
                    <h4>{subheader}</h4>
                    <H1 content={header} />
                </div>
            </div>
            <div className="row l-Inner">
                {partners?.map(
                    ({
                        partner: {
                            title,
                            acf: {
                                logo: {
                                    localFile: {
                                        childImageSharp: { gatsbyImageData },
                                    },
                                },
                                link,
                            },
                        },
                    }) => {
                        return (
                            <div
                                key={title}
                                className="small-12 small-6 medium-4"
                            >
                                <a
                                    target="_blank"
                                    rel="noopener noreferrer nofollow"
                                    href={link}
                                    className={styles.OurPartners__Partner}
                                    {...dataAttributes(
                                        "section-eventpartners-logo",
                                        title
                                    )}
                                >
                                    <GatsbyImage
                                        className={styles.OurPartners__Logo}
                                        alt={title}
                                        loading="eager"
                                        placeholder="none"
                                        image={gatsbyImageData}
                                    />
                                </a>
                            </div>
                        )
                    }
                )}
            </div>
        </section>
    )
}

OurPartners.propTypes = {
    props: PropTypes.exact({
        header: PropTypes.string.isRequired,
        subheader: PropTypes.string.isRequired,
        partners: PropTypes.array.isRequired,
    }).isRequired,
}

export default OurPartners
