import React from "react"
import useEventPhrases from "../hooks/useEventPhrases"
import usePostsContext from "../hooks/usePostsContext"
import { graphql } from "gatsby"

import BannerEvents from "../components/single-page/event/banner/Presentation"
import Layout from "../components/layout/Layout"
import OurPartners from "../components/sections/our-partners/OurPartners"
import ImageText from "../components/sections/image-text/ImageText"
import Speakers from "../components/sections/speakers/Speakers"
import Schedule from "../components/sections/schedule/Schedule"
import Contact from "../components/sections/contact/Contact"
import ThreeTiles from "../components/sections/three-tiles/ThreeTiles"
import Form from "../components/sections/form/Form"
import Seo from "../components/functional/seo/Seo"
import { isBrowser } from "../utils"

export const query = graphql`
    query($id: String!) {
        wpEvent(id: { eq: $id }) {
            ...onlineEvent
        }
    }
`

const SingleEvent = ({ data }) => {
    const {
        title,
        id,
        terms,
        content: excerpt,
        acf: {
            date,
            language,
            time,
            cost,
            overwriteDetails,
            details: overwrittenDetails,
            showBannerCta,
            isafterevent,
            showPlayButton,
            image,
            media,
            intro,
            cta,
            type,
            youtubeid,
            recordings,
            videoplaceholder: videoPlaceholder,
            showlogos,
            showPartners,
            partners,
            aboutevent,
            speakers,
            schedule,
            partner,
            registrationForm,
            materialsForm,
            showThreeTiles,
            overwriteContactPeople,
            contactPeople,
            threeTiles: { buttonText, header, subheader },
            footerEmail,
            showMaterialsForm
        },
    } = data.wpEvent

    const defaultPhrases = useEventPhrases()
    const recentEvents = usePostsContext()
        .events.all.filter(event => event.id !== id)
        .slice(0, 3)

    const overwrittenContactPeople = overwriteContactPeople
        ? contactPeople
        : false

    return (
        <Layout footerEmail={footerEmail}>
            <Seo
                lang={language}
                ogImage={
                    image.localFile.childImageSharp.gatsbyImageData.images
                        .fallback.src
                }
                description={excerpt}
                title={title}
            >
                <script type="application/ld+json">
                    {`
                        {
                        "@context": "https://schema.org",
                        "@type": "Event",
                        "about": "${
                            excerpt ? excerpt.replace(/<[^>]*>?/gm, "") : ""
                        }",
                        "startDate": "${new Date(date).toISOString()}",
                        "organizer": {
                            "@type": "Organization",
                            "name": "Future processing",
                            "url": "https://future-processing.com"
                        },
                        "performer": [${speakers?.speakers?.map(speaker => {
                            return `{
                                "@type": "Person",
                                "name": "${speaker?.speaker?.title}"
                            }`
                        })}]
                    }
                    `}
                </script>
                <script type="application/ld+json">
                    {`
                        {
                        "@context": "https://schema.org",
                        "@type": "BreadcrumbList",
                        "itemListElement":[
                            {
                                "@type": "ListItem",
                                "position": 1,
                                "item":{
                                    "@id": "https://itinsights.tech",
                                    "name": "Home"
                                }
                            },
                            {
                                "@type": "ListItem",
                                "position": 2,
                                "item":{
                                    "@id": "${
                                        isBrowser() ? window.location.href : ""
                                    }",
                                    "name": "${title}"
                                }
                            }
                        ]
                    }
                    `}
                </script>
            </Seo>
            <BannerEvents
                props={{
                    title,
                    date,
                    time,
                    media,
                    cost,
                    intro,
                    type,
                    videoPlaceholder,
                    youtubeid,
                    isafterevent,
                    showPlayButton,
                    terms,
                    backgroundImage: image,
                    cta,
                    showBannerCta,
                    overwriteDetails,
                    overwrittenDetails: overwrittenDetails,
                    showlogos,
                    partner,
                    registrationForm,
                    materialsForm,
                    showBreadcrumbs: true,
                    showMaterialsForm
                }}
            />
            <ImageText props={aboutevent} />
            {showMaterialsForm && !cta.linkToOutsideRegistration && (
                <Form
                    props={{
                        formData: materialsForm,
                        defaultHeader:
                            defaultPhrases[language].materialsForm.header,
                        defaultSubheader:
                            defaultPhrases[language].materialsForm.subheader,
                        defaultHubspotFormId:
                            defaultPhrases[language].materialsForm
                                .hubspotFormId,
                        defaultHubspotFormSlug:
                            defaultPhrases[language].materialsForm
                                .hubspotFormSlug,
                        defaultThankYouHeader:
                            defaultPhrases[language].materialsForm
                                .thankYouHeader,
                        defaultThankYouText:
                            defaultPhrases[language].materialsForm.thankYouText,
                    }}
                />
            )}
            <Speakers
                props={{
                    speakers,
                    defaultSubtitle: defaultPhrases[language].speakers.subtitle,
                    defaultTitle: defaultPhrases[language].speakers.title,
                    defaultIntro: defaultPhrases[language].speakers.intro,
                    buttonText: defaultPhrases[language].speakers.buttonText,
                }}
            />
            <Schedule props={schedule} />
            {showPartners && <OurPartners props={partners} />}
            {!isafterevent && !cta.linkToOutsideRegistration && (
                <Form
                    props={{
                        formData: registrationForm,
                        defaultHeader:
                            defaultPhrases[language].registrationForm.header,
                        defaultSubheader:
                            defaultPhrases[language].registrationForm.subheader,
                        defaultHubspotFormId:
                            defaultPhrases[language].registrationForm
                                .hubspotFormId,
                        defaultHubspotFormSlug:
                            defaultPhrases[language].registrationForm
                                .hubspotFormSlug,
                        defaultThankYouHeader:
                            defaultPhrases[language].registrationForm
                                .thankYouHeader,
                        defaultThankYouText:
                            defaultPhrases[language].registrationForm
                                .thankYouText,
                    }}
                />
            )}
            {showThreeTiles && (
                <ThreeTiles
                    props={{
                        type: "events",
                        subheader:
                            subheader ||
                            defaultPhrases[language].threeTiles.subheader,
                        header:
                            header ||
                            defaultPhrases[language].threeTiles.header,
                        buttonText:
                            buttonText ||
                            defaultPhrases[language].threeTiles.buttonText,
                        customTiles: recentEvents,
                        showCustomTiles: true,
                        doubleBottomPadding: true,
                    }}
                />
            )}

            <Contact props={{ language, overwrittenContactPeople }} />
        </Layout>
    )
}
export default SingleEvent