import {useStaticQuery, graphql} from "gatsby"

const useEventPhrases = () => {
    const data = useStaticQuery(
        graphql`
            query {
                wp {
                    eventdefaults {
                        acf {
                            threeTiles {
                                en {
                                    buttonText
                                    header
                                    subheader
                                }
                                de {
                                    buttonText
                                    header
                                    subheader
                                }
                            }
                            speakers {
                                en {
                                    subtitle
                                    title
                                    intro
                                    buttonText
                                }
                                de {
                                    subtitle
                                    title
                                    intro
                                    buttonText
                                }
                            }
                            materialsForm {
                                de {
                                    header
                                    subheader
                                    hubspotFormId
                                    hubspotFormSlug
                                    thankYouHeader
                                    thankYouText
                                }
                                en {
                                    header
                                    subheader
                                    hubspotFormId
                                    hubspotFormSlug
                                    thankYouHeader
                                    thankYouText
                                }
                            }
                            registrationForm {
                                de {
                                    header
                                    subheader
                                    hubspotFormId
                                    hubspotFormSlug
                                    thankYouHeader
                                    thankYouText
                                }
                                en {
                                    header
                                    subheader
                                    hubspotFormId
                                    hubspotFormSlug
                                    thankYouHeader
                                    thankYouText
                                }
                            }
                        }
                    }
                }
            }
        `
    )
    const {
        wp: {
            eventdefaults: {
                acf: {
                    threeTiles: {
                        en: {
                            buttonText: threeTilesButtonTextEn,
                            header: threeTilesHeaderEn,
                            subheader: threeTilesSubheaderEn,
                        },
                        de: {
                            buttonText: threeTilesButtonTextDe,
                            header: threeTilesHeaderDe,
                            subheader: threeTilesSubheaderDe,
                        },
                    },
                    speakers: {
                        en: {
                            subtitle: speakersSubtitleEn,
                            title: speakersTitleEn,
                            intro: speakersIntroEn,
                            buttonText: speakersButtonTextEn,
                        },
                        de: {
                            subtitle: speakersSubtitleDe,
                            title: speakersTitleDe,
                            intro: speakersIntroDe,
                            buttonText: speakersButtonTextDe,
                        },
                    },
                    materialsForm: {
                        en: {
                            header: materialsFormHeaderEn,
                            subheader: materialsFormSubheaderEn,
                            hubspotFormId: materialsFormHubspotFormIdEn,
                            hubspotFormSlug: materialsFormHubspotFormSlugEn,
                            thankYouHeader: materialsFormThankYouHeaderEn,
                            thankYouText: materialsFormThankYouTextEn,
                        },
                        de: {
                            header: materialsFormHeaderDe,
                            subheader: materialsFormSubheaderDe,
                            hubspotFormId: materialsFormHubspotFormIdDe,
                            hubspotFormSlug: materialsFormHubspotFormSlugDe,
                            thankYouHeader: materialsFormThankYouHeaderDe,
                            thankYouText: materialsFormThankYouTextDe,
                        }
                    },
                    registrationForm: {
                        en: {
                            header: registrationFormHeaderEn,
                            subheader: registrationFormSubheaderEn,
                            hubspotFormId: registrationFormHubspotFormIdEn,
                            hubspotFormSlug: registrationFormHubspotFormSlugEn,
                            thankYouHeader: registrationFormThankYouHeaderEn,
                            thankYouText: registrationFormThankYouTextEn,
                        },
                        de: {
                            header: registrationFormHeaderDe,
                            subheader: registrationFormSubheaderDe,
                            hubspotFormId: registrationFormHubspotFormIdDe,
                            hubspotFormSlug: registrationFormHubspotFormSlugDe,
                            thankYouHeader: registrationFormThankYouHeaderDe,
                            thankYouText: registrationFormThankYouTextDe,
                        },
                    },
                },
            },
        },
    } = data

    return {
        en: {
            threeTiles: {
                buttonText: threeTilesButtonTextEn,
                header: threeTilesHeaderEn,
                subheader: threeTilesSubheaderEn,
            },
            speakers: {
                subtitle: speakersSubtitleEn,
                title: speakersTitleEn,
                intro: speakersIntroEn,
                buttonText: speakersButtonTextEn,
            },
            materialsForm: {
                header: materialsFormHeaderEn,
                subheader: materialsFormSubheaderEn,
                hubspotFormId: materialsFormHubspotFormIdEn,
                hubspotFormSlug: materialsFormHubspotFormSlugEn,
                thankYouHeader: materialsFormThankYouHeaderEn,
                thankYouText: materialsFormThankYouTextEn,
            },
            registrationForm: {
                header: registrationFormHeaderEn,
                subheader: registrationFormSubheaderEn,
                hubspotFormId: registrationFormHubspotFormIdEn,
                hubspotFormSlug: registrationFormHubspotFormSlugEn,
                thankYouHeader: registrationFormThankYouHeaderEn,
                thankYouText: registrationFormThankYouTextEn,
            },
        },
        de: {
            threeTiles: {
                buttonText: threeTilesButtonTextDe,
                header: threeTilesHeaderDe,
                subheader: threeTilesSubheaderDe,
            },
            speakers: {
                subtitle: speakersSubtitleDe,
                title: speakersTitleDe,
                intro: speakersIntroDe,
                buttonText: speakersButtonTextDe,
            },
            materialsForm: {
                header: materialsFormHeaderDe,
                subheader: materialsFormSubheaderDe,
                hubspotFormId: materialsFormHubspotFormIdDe,
                hubspotFormSlug: materialsFormHubspotFormSlugDe,
                thankYouHeader: materialsFormThankYouHeaderDe,
                thankYouText: materialsFormThankYouTextDe,
            },
            registrationForm: {
                header: registrationFormHeaderDe,
                subheader: registrationFormSubheaderDe,
                hubspotFormId: registrationFormHubspotFormIdDe,
                hubspotFormSlug: registrationFormHubspotFormSlugDe,
                thankYouHeader: registrationFormThankYouHeaderDe,
                thankYouText: registrationFormThankYouTextDe,
            },
        },
    }
}

export default useEventPhrases
