import React from "react"
import propTypes from "prop-types"
import classnames from "classnames"

import * as styles from "./styles"
import { GatsbyImage } from "gatsby-plugin-image"

import LinearPattern from "../../../ui/linear-pattern/LinearPattern"
import Partners from "./partners/Partners"
import Categories from "./categories/Categories"
import Details from "./details/Details"
import Button from "../../../ui/button/Button"
import VideoPlayer from "../../../common/video-player/VideoPlayer"

import { getLabelIcon, getLabelType } from "../../../../utils/label"
import { H1 } from "../../../ui/headers/Headers"
import Breadcrumbs from "../../../common/breadcrumbs/Breadcrumbs"

const Presentation = ({ props = {} }) => {
    const {
        title,
        intro,
        date,
        cost,
        type,
        time,
        overwrittenDetails,
        backgroundImage,
        isafterevent,
        showPlayButton,
        overwriteDetails,
        showBannerCta,
        termNames,
        media,
        showlogos,
        youtubeid,
        videoPlaceholder,
        cta,
        partner,
        showBreadcrumbs,
    } = props

    const videoFile = videoPlaceholder ? videoPlaceholder.mediaItemUrl : false

    const imageFile = backgroundImage
        ? backgroundImage.localFile.childImageSharp.gatsbyImageData
        : false

    const isVideo = media === "video"
    let chosenMedia

    if (isVideo && videoFile) {
        chosenMedia = (
            <VideoPlayer
                centerPlay
                showPlayButton={showPlayButton}
                placeholder={{ type: "video", src: videoFile }}
                id={youtubeid}
            />
        )
    } else if (imageFile) {
        chosenMedia = (
            <GatsbyImage
                alt={
                    backgroundImage.altText !== ""
                        ? backgroundImage.altText
                        : title
                }
                image={imageFile}
            />
        )
    }

    return (
        <div
            className={classnames(styles.Banner, styles.BannerExpanded, {
                [styles.BannerIsAfterEvent]: isafterevent,
            })}
        >
            <div className={classnames(styles.Banner__Container, "t-Dark")}>
                <div
                    className={classnames(
                        styles.Banner__Content,
                        "l-Inner row",
                        {
                            [styles.Banner__ContentIsVideo]: isVideo,
                        }
                    )}
                >
                    <div
                        className={classnames(
                            styles.Banner__Text,
                            "small-12 large-5",
                            {
                                [styles.Banner__TextIsVideo]: isVideo,
                            }
                        )}
                    >
                        {showBreadcrumbs && (
                            <Breadcrumbs
                                page="Events"
                                title={title}
                                link="/events"
                            />
                        )}
                        {type && (
                            <div className={styles.Banner__Label}>
                                <Button
                                    small
                                    label
                                    iconLeft={getLabelIcon(type)}
                                >
                                    {getLabelType(type)}
                                </Button>
                            </div>
                        )}
                        <H1 className="h1--Big" content={title} />
                        <div dangerouslySetInnerHTML={{ __html: intro }} />
                        {isafterevent && date && time && (
                            <div
                                className={classnames(
                                    styles.Banner__DetailsAfterEvent,
                                    "row"
                                )}
                            >
                                <div className="small-6">
                                    <h4>When:</h4>
                                    <h2 className="h2--Small">{date}</h2>
                                </div>
                                <div className="small-6">
                                    <h4>Time:</h4>
                                    <h2 className="h2--Small">{time}</h2>
                                </div>
                            </div>
                        )}
                    </div>
                    {termNames && (
                        <Categories categories={termNames} isVideo={isVideo} />
                    )}
                    {showlogos && (
                        <Partners
                            isAfterEvent={isafterevent}
                            isVideo={isVideo}
                            partner={partner}
                        />
                    )}
                    <div
                        className={classnames(
                            styles.Banner__MediaContainer,
                            "medium-8",
                            {
                                "small-12": isVideo,
                                [styles.Banner__MediaContainerIsVideo]: isVideo,
                            }
                        )}
                    >
                        {chosenMedia}
                    </div>
                    {!isafterevent && (
                        <Details
                            date={date}
                            time={time}
                            cost={cost}
                            overwrittenDetails={overwrittenDetails}
                            overwriteDetails={overwriteDetails}
                            showBannerCta={showBannerCta}
                            cta={cta}
                            isVideo={isVideo}
                        />
                    )}
                </div>
            </div>
            <div className={styles.Banner__LinearPattern}>
                <LinearPattern />
            </div>
        </div>
    )
}

Presentation.propTypes = {
    props: propTypes.exact({
        title: propTypes.string,
        intro: propTypes.string,
        date: propTypes.string,
        time: propTypes.string,
        cost: propTypes.string,
        backgroundImage: propTypes.object,
        isafterevent: propTypes.bool,
        showPlayButton: propTypes.bool,
        media: propTypes.string,
        showlogos: propTypes.bool,
        youtubeid: propTypes.string,
        videoPlaceholder: propTypes.object,
        cta: propTypes.object,
        partner: propTypes.object,
        termNames: propTypes.array,
        showBreadcrumbs: propTypes.bool,
        type: propTypes.string,
    }).isRequired,
}

export default Presentation
