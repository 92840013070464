import React, { useState } from "react"
import * as styles from "./styles"
import classnames from "classnames"
import PropTypes from "prop-types"
import { GatsbyImage } from "gatsby-plugin-image"
import LinearPattern from "../../ui/linear-pattern/LinearPattern"
import ConnectingLine from "../../ui/connecting-line/ConnectingLine"
import { H1 } from "../../ui/headers/Headers"

const ImageText = ({
    props: { subtitle, title, text, image, showmore: showMore },
}) => {
    const [textVisible, setTextVisible] = useState(false)

    return (
        <section className={styles.ImageText}>
            <ConnectingLine />
            <div className="l-Inner row">
                <div
                    className={classnames(
                        "xsmall-10 xsmall-offset-2 small-8 small-offset-4 large-6" +
                            " large-offset-0",
                        styles.ImageText__Image
                    )}
                >
                    {
                        <GatsbyImage
                            imgStyle={{ objectPosition: "center top" }}
                            image={
                                image.localFile.childImageSharp.gatsbyImageData
                            }
                            alt={image.altText !== "" ? image.altText : title}
                        />
                    }
                </div>
                <div
                    className={classnames(
                        "xsmall-12 large-5 large-offset-1",
                        styles.ImageText__Text,
                        {
                            [styles.ImageText__TextIsActive]: textVisible,
                            [styles.ImageText__TextWithShowMore]: showMore,
                        }
                    )}
                >
                    <h4 dangerouslySetInnerHTML={{ __html: subtitle }} />
                    <H1 content={title} />
                    <div dangerouslySetInnerHTML={{ __html: text }} />
                    {showMore && (
                        <div
                            role="button"
                            tabIndex={0}
                            className={classnames(styles.ImageText__ShowMore, {
                                [styles.ImageText__ShowMoreIsActive]: textVisible,
                            })}
                            onClick={() => setTextVisible(!textVisible)}
                            onKeyDown={() => setTextVisible(!textVisible)}
                        >
                            {textVisible ? "show less" : "show more"}
                        </div>
                    )}
                </div>
            </div>
            <div className={styles.ImageText__LinearPattern}>
                <LinearPattern />
            </div>
        </section>
    )
}

ImageText.propTypes = {
    props: PropTypes.exact({
        subtitle: PropTypes.string,
        title: PropTypes.string,
        text: PropTypes.string,
        image: PropTypes.object,
    }).isRequired,
}

export default ImageText
