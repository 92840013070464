import React from "react"
import PropTypes from "prop-types"
import * as styles from "./styles"

const ConnectingLine = (bottom) => {
    return <div className={`${styles.ConnectingLine} ${bottom ? styles.ConnectingLine__Bottom : ''}`} />
}

ConnectingLine.propTypes = {
    bottom: PropTypes.bool,
}

export default ConnectingLine
