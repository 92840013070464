import React from "react"
import propTypes from "prop-types"

import * as styles from "./styles"
import classnames from "classnames"

const Partners = ({ categories, isVideo }) => {
    return (
        <ul
            className={classnames(styles.Categories, "u-Small", {
                [styles.CategoriesIsVideo]: isVideo,
            })}
        >
            {categories?.map((element, index) => (
                <li key={index}>{element}</li>
            ))}
        </ul>
    )
}

Partners.propTypes = {
    categories: propTypes.array,
}

export default Partners
