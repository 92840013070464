import React from "react"
import propTypes from "prop-types"

import classnames from "classnames"
import * as styles from "./styles"
import dataAttributes from "../../../../../utils/dataAttributes"

import Icon from "../../../../ui/icons/Icon"

const Details = ({
    time,
    cost,
    date,
    cta,
    showBannerCta,
    overwrittenDetails,
    overwriteDetails,
}) => {
    const scrollTo = () => {
        if (
            cta.linkscrollid &&
            parseInt(cta.linkscrollid) !== cta.linkscrollid
        ) {
            // eslint-disable-line eqeqeq
            const anchor = document.querySelector("#" + cta.linkscrollid)

            if (anchor) {
                const scrollPosition =
                    anchor.getBoundingClientRect().top +
                    window.pageYOffset -
                    100
                window.scrollTo({ top: scrollPosition, behavior: "smooth" })
            }
        }
    }

    const defaultData = [
        { title: "When:", value: date },
        { title: "Time:", value: time },
        { title: "Cost:", value: cost },
    ]

    const detailsData = overwriteDetails ? overwrittenDetails : defaultData

    return (
        <div className={classnames(styles.Details, "row small-12")}>
            {detailsData?.map((element, index) => {
                return (
                    <>
                        {element.value && element.title && (
                            <div
                                className={classnames(
                                    styles.Details__Box,
                                    "small-6 large-3"
                                )}
                                key={index}
                            >
                                <h4>{element.title}</h4>
                                <h2
                                    className="h2--Small"
                                    dangerouslySetInnerHTML={{
                                        __html: element.value,
                                    }}
                                />
                            </div>
                        )}
                    </>
                )
            })}
            {showBannerCta && (
                <>
                    {cta.linkToOutsideRegistration &&
                    cta.registrationLink &&
                    cta.registrationLink.url ? (
                        <a
                            className={classnames(
                                "small-6 large-3 t-BrandGradient",
                                styles.Details__Register
                            )}
                            href={cta.registrationLink.url}
                            target="_blank"
                            rel="noreferrer"
                            {...dataAttributes(
                                "banner-event-register-copy",
                                "Banner - event - register"
                            )}
                        >
                            <h2
                                dangerouslySetInnerHTML={{
                                    __html: cta.header,
                                }}
                            />
                            {cta.linktext}
                        </a>
                    ) : (
                        <div
                            onClick={scrollTo}
                            className={classnames(
                                styles.Details__Register,
                                "small-6 large-3 t-BrandGradient"
                            )}
                            {...dataAttributes(
                                "banner-event-register-copy",
                                "Banner - event - register"
                            )}
                        >
                            <h2
                                dangerouslySetInnerHTML={{
                                    __html: cta.header,
                                }}
                            />

                            <button>
                                {cta.linktext} <Icon icon="arrow2" />
                            </button>
                        </div>
                    )}
                </>
            )}
        </div>
    )
}

Details.propTypes = {
    partner: propTypes.object,
}

export default Details
