import React from "react"
import { graphql, useStaticQuery } from "gatsby"
import * as styles from "./styles"
import classnames from "classnames"
import PropTypes from "prop-types"

import LinkedinIcon from "../../../../images/linkedin.svg"
import { GatsbyImage } from "gatsby-plugin-image"

import dataAttributes from "../../../../utils/dataAttributes"
import Icon from "../../../ui/icons/Icon"

const AgendaSpeaker = ({ name, position, linkedinlink, photo, email }) => {
    const data = useStaticQuery(
        graphql`
            query {
                wp {
                    general {
                        acf {
                            generalSpeakersPlaceholder {
                                ...gatsbyFluidImage
                            }
                        }
                    }
                    contact {
                        acf {
                            contactEn {
                                contactEnPersonText
                                contactEnEmailSubject
                            }
                        }
                    }
                }
            }
        `
    )

    const {
        general: {
            acf: {
                generalSpeakersPlaceholder: {
                    localFile: {
                        childImageSharp: { gatsbyImageData: fluid },
                    },
                },
            },
        },
        contact: {
            acf: {
                contactEn: { contactEnPersonText, contactEnEmailSubject },
            },
        },
    } = data.wp

    return (
        <div
            className={classnames(
                styles.AgendaSpeaker,
                "column small-12 large-6"
            )}
        >
            <div className={styles.AgendaSpeaker__Photo}>
                {!photo && fluid && <GatsbyImage image={fluid} />}
                {photo && (
                    <GatsbyImage
                        image={photo.localFile.childImageSharp.gatsbyImageData}
                    />
                )}

                {linkedinlink && (
                    <a
                        {...dataAttributes(
                            "section-events-accordionlist-linkedin",
                            name
                        )}
                        href={linkedinlink}
                        target="_blank"
                        rel="noopener noreferrer"
                        className={styles.AgendaSpeaker__Linkedin}
                        onClick={e => {
                            e.stopPropagation()
                        }}
                    >
                        <img src={LinkedinIcon} alt="linkedin" />
                    </a>
                )}
            </div>
            <div
                className={classnames(
                    styles.AgendaSpeaker__Text,
                    "u-Secondary"
                )}
            >
                <p
                    className={styles.AgendaSpeaker__Name}
                    dangerouslySetInnerHTML={{ __html: name }}
                />
                <p
                    className={styles.AgendaSpeaker__Position}
                    dangerouslySetInnerHTML={{ __html: position }}
                />
                {email && (
                    <a
                        className={styles.AgendaSpeaker__Contact}
                        href={
                            "mailto:" +
                            email +
                            "?subject=" +
                            contactEnEmailSubject
                        }
                        onClick={e => {
                            e.stopPropagation()
                        }}
                    >
                        {contactEnPersonText} <Icon icon="arrow2" />
                    </a>
                )}
            </div>
        </div>
    )
}

AgendaSpeaker.propTypes = {
    name: PropTypes.string,
    position: PropTypes.string,
    linkedinlink: PropTypes.string,
    email: PropTypes.string,
    photo: PropTypes.object,
}

export default AgendaSpeaker
