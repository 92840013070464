import React, { useState } from "react"
import PropTypes from "prop-types"

import Speaker from "./speaker/Speaker"
import ConnectingLine from "../../ui/connecting-line/ConnectingLine"
import LinearPattern from "../../ui/linear-pattern/LinearPattern"
import { H1 } from "../../ui/headers/Headers"
import * as styles from "./styles"
import Button from "../../ui/button/Button"

const Speakers = ({
    props: {
        defaultSubtitle,
        defaultTitle,
        defaultIntro,
        buttonText,
        speakers: { subtitle, title, intro, speakers },
    },
}) => {
    const speakersShownCount = 3
    const speakersCount = speakers.length
    const [mobileSpeakersVisible, setMobileSpeakersVisible] = useState(
        speakersShownCount
    )

    const showAllSpeakers = () => {
        setMobileSpeakersVisible(speakersCount)
    }

    return (
        <section className="t-Dark">
            <ConnectingLine />
            <LinearPattern />
            <div className={`row l-Inner ${styles.Speakers__Content}`}>
                <div
                    className={`small-12 medium-10 large-4 ${styles.Speakers__Intro}`}
                >
                    <h4
                        dangerouslySetInnerHTML={{
                            __html: subtitle || defaultSubtitle,
                        }}
                    />
                    <H1 content={title || defaultTitle} />
                    <div
                        dangerouslySetInnerHTML={{
                            __html: intro || defaultIntro,
                        }}
                    />
                </div>
                <div className="small-12 large-8">
                    <div className={styles.Speakers__People}>
                        {speakers.map((speaker, index) => {
                            const {
                                speaker: {
                                    title,
                                    acf: {
                                        position,
                                        linkedinlink,
                                        companylogo,
                                        companyurl,
                                        photo,
                                    },
                                },
                            } = speaker
                            return (
                                <Speaker
                                    key={title + index}
                                    name={title}
                                    position={position}
                                    linkedinlink={linkedinlink}
                                    companyLogo={companylogo}
                                    companyUrl={companyurl}
                                    photo={photo}
                                    active={index < mobileSpeakersVisible}
                                />
                            )
                        })}
                    </div>
                    {mobileSpeakersVisible < speakersCount && (
                        <div className={styles.Speakers__ShowMore}>
                            <Button onClick={showAllSpeakers} large>
                                {`${buttonText} (${
                                    speakersCount - speakersShownCount
                                })`}
                            </Button>
                        </div>
                    )}
                </div>
            </div>
        </section>
    )
}

Speakers.propTypes = {
    props: PropTypes.exact({
        subtitle: PropTypes.string,
        title: PropTypes.string,
        intro: PropTypes.string,
        speakers: PropTypes.array,
    }).isRequired,
}

export default Speakers
