// extracted by mini-css-extract-plugin
export var Banner = "Styles-module--Banner--34j9-";
export var Banner__Container = "Styles-module--Banner__Container--1EvTS";
export var Banner__Content = "Styles-module--Banner__Content--2tCOY";
export var Banner__ContentIsVideo = "Styles-module--Banner__Content--IsVideo--1br3a";
export var Banner__Label = "Styles-module--Banner__Label--1P-Kr";
export var Banner__Text = "Styles-module--Banner__Text--3_zpM";
export var Banner__TextIsVideo = "Styles-module--Banner__Text--IsVideo--2tpXe";
export var Banner__MediaContainer = "Styles-module--Banner__MediaContainer--3lfj5";
export var Banner__MediaContainerIsVideo = "Styles-module--Banner__MediaContainer--IsVideo--e9NIC";
export var Banner__DetailsAfterEvent = "Styles-module--Banner__DetailsAfterEvent--2OEhL";
export var Banner__LinearPattern = "Styles-module--Banner__LinearPattern--27wQ5";
export var BannerExpanded = "Styles-module--Banner--Expanded--1O7Ss";
export var BannerIsAfterEvent = "Styles-module--Banner--IsAfterEvent--2mufu";