import React, { useState } from "react"
import * as styles from "./styles"
import classnames from "classnames"
import PropTypes from "prop-types"
import Icon from "../../../ui/icons/Icon"
import AgendaSpeaker from "../agenda-speaker/AgendaSpeaker"
import dataAttributes from "../../../../utils/dataAttributes"
import { createGtmTagClass } from "../../../../utils"

const Event = ({ intro, place, time, title, people }) => {
    const [isOpened, setIsOpened] = useState(false)

    return (
        <li
            className={classnames(
                styles.Event,
                { [styles.EventIsHoverable]: people },
                { [styles.EventIsOpened]: isOpened },
                createGtmTagClass("EventAccordionListItem")
            )}
            {...dataAttributes("section-events-accordionlist-item", title)}
            role="presentation"
            onClick={() => {
                setIsOpened(!isOpened)
            }}
        >
            <div
                className={styles.Event__Time}
                dangerouslySetInnerHTML={{ __html: time }}
            />
            <h3
                className="h3--Big"
                dangerouslySetInnerHTML={{ __html: title }}
            />
            <p dangerouslySetInnerHTML={{ __html: intro }} />
            {place && (
                <p className={classnames("u-Secondary", styles.Event__Place)}>
                    {place}
                </p>
            )}
            {people && (
                <>
                    <div className={styles.Event__Icon}>
                        <Icon icon="arrowhead" />
                    </div>
                    <div className={classnames(styles.Event__Speakers, "row")}>
                        {people.map((person, index) => {
                            const {
                                title,
                                acf: { position, linkedinlink, photo, email },
                            } = person.person

                            return (
                                <AgendaSpeaker
                                    key={index}
                                    name={title}
                                    position={position}
                                    linkedinlink={linkedinlink}
                                    photo={photo}
                                    email={email}
                                />
                            )
                        })}
                    </div>
                </>
            )}
        </li>
    )
}

Event.propTypes = {
    intro: PropTypes.string,
    place: PropTypes.string,
    time: PropTypes.string,
    title: PropTypes.string,
    people: PropTypes.array,
}

export default Event
