import React from "react"
import propTypes from "prop-types"
import HubspotForm from "../../functional/hubspot/HubspotForm"
import * as styles from "./styles"
import { H1 } from "../../ui/headers/Headers"

const Form = ({
    props: {
        title,
        defaultSubheader,
        defaultHeader,
        defaultHubspotFormId,
        defaultHubspotFormSlug,
        defaultThankYouHeader,
        defaultThankYouText,
        formData: {
            sectionId,
            subheader,
            header,
            hubspotFormId,
            hubspotFormSlug,
            thankYouHeader,
            thankYouText,
        },
    },
}) => {
    return (
        <section id={sectionId} className={styles.Form}>
            <div className="row l-Inner align-center">
                <div className="columns small-12 large-8 xlarge-6">
                    <h4
                        dangerouslySetInnerHTML={{
                            __html: subheader || defaultSubheader,
                        }}
                    />
                    <H1 content={header || defaultHeader} />
                    {title && (
                        <div className={styles.Form__Title}>
                            <strong>Title: </strong>
                            <p dangerouslySetInnerHTML={{ __html: title }} />
                        </div>
                    )}
                </div>
                <div className="columns small-12 large-10">
                    {(hubspotFormId || defaultHubspotFormId) &&
                        (hubspotFormSlug || defaultHubspotFormSlug) && (
                            <HubspotForm
                                formId={hubspotFormId || defaultHubspotFormId}
                                slug={hubspotFormSlug || defaultHubspotFormSlug}
                                header={thankYouHeader || defaultThankYouHeader}
                                text={thankYouText || defaultThankYouText}
                            />
                        )}
                </div>
            </div>
        </section>
    )
}

Form.propTypes = {
    defaultSubheader: propTypes.string,
    defaultHeader: propTypes.string,
    defaultHubspotFormId: propTypes.string,
    defaultHubspotFormSlug: propTypes.string,
    defaultThankYouHeader: propTypes.string,
    defaultThankYouText: propTypes.string,
    formData: {
        sectionId: propTypes.string,
        header: propTypes.string,
        subheader: propTypes.string,
        hubspotFormId: propTypes.string,
        hubspotFormSlug: propTypes.string,
        thankYouHeader: propTypes.string,
        thankYouText: propTypes.string,
    },
}

export default Form
