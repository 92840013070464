import React from "react"
import * as styles from "./styles"
import PropTypes from "prop-types"
import { GatsbyImage } from "gatsby-plugin-image"
import Event from "./event/Event"
import classnames from "classnames"
import LinearPattern from "../../ui/linear-pattern/LinearPattern"
import { H1 } from "../../ui/headers/Headers"

const Schedule = ({
    props: { subtitle, title, agenda, image, darkBackground },
}) => {
    return (
        <section
            className={classnames(styles.Schedule, {
                [styles.ScheduleIsDark]: darkBackground,
            })}
        >
            <div className="l-Inner row">
                <div className="small-12 medium-10 large-5">
                    <h4 dangerouslySetInnerHTML={{ __html: subtitle }} />
                    <H1 content={title} />
                </div>
            </div>
            <div
                className={classnames(
                    styles.Schedule__Container,
                    "l-Inner row"
                )}
            >
                <div
                    className={classnames(
                        styles.Schedule__Agenda,
                        "small-12 medium-10 xlarge-9"
                    )}
                >
                    <ul className={styles.Schedule__Content}>
                        {agenda?.map((event, index) => {
                            const { intro, place, time, title, people } = event

                            return (
                                <Event
                                    key={index}
                                    intro={intro}
                                    place={place}
                                    time={time}
                                    title={title}
                                    people={people}
                                />
                            )
                        })}
                    </ul>
                </div>
                {image && (
                    <GatsbyImage
                        className={styles.Schedule__Image}
                        image={image.localFile.childImageSharp.gatsbyImageData}
                        imgStyle={{ objectPosition: "center top" }}
                    />
                )}
                <div className={styles.Schedule__LinearPattern}>
                    <LinearPattern />
                </div>
            </div>
        </section>
    )
}

Schedule.propTypes = {
    props: PropTypes.exact({
        subtitle: PropTypes.string,
        title: PropTypes.string,
        image: PropTypes.object,
        agenda: PropTypes.array,
        darkBackground: PropTypes.bool,
    }).isRequired,
}

export default Schedule
