import React from "react"
import PropTypes from "prop-types"
import classnames from "classnames"

import * as styles from "./styles"
import { GatsbyImage } from "gatsby-plugin-image"
import Button from "../../ui/button/Button"

const VideoPlayer = ({
    id,
    placeholder,
    centerPlay,
    dataAtts,
    showPlayButton,
    triggerPlayButtonClick,
}) => {

    const handlePlayButtonClick = () => {
        triggerPlayButtonClick()
    }

    return (
        <div className={styles.VideoPlayer}>
            {id && (
                <div
                    className={classnames(styles.VideoPlayer__PlayButton, {
                        [styles.VideoPlayer__PlayButtonIsCenter]: centerPlay,
                    })}
                >
                    {showPlayButton && (
                        <Button
                            dataAtts={dataAtts}
                            play
                            onClick={handlePlayButtonClick}
                        ></Button>
                    )}
                </div>
            )}

            {placeholder.type === "video" ? (
                <video
                    className={styles.VideoPlayer__Placeholder}
                    muted
                    autoPlay
                    loop
                    src={placeholder.src}
                />
            ) : (
                <GatsbyImage alt={placeholder.alt} image={placeholder.src} />
            )}
        </div>
    )
}

VideoPlayer.propTypes = {
    id: PropTypes.string,
    placeholder: PropTypes.object.isRequired,
    dataAtts: PropTypes.object.isRequired,
}

export default VideoPlayer
