import React from "react"
import { graphql, useStaticQuery } from "gatsby"
import * as styles from "./styles"
import PropTypes from "prop-types"
import { GatsbyImage } from "gatsby-plugin-image"
import LinkedinIcon from "../../../../images/linkedin.svg"
import dataAttributes from "../../../../utils/dataAttributes"
import classnames from "classnames"

const Speaker = ({
    name,
    position,
    linkedinlink,
    companyLogo,
    companyUrl,
    photo,
    active,
}) => {
    const data = useStaticQuery(
        graphql`
            query {
                wp {
                    general {
                        acf {
                            generalSpeakersPlaceholder {
                                ...gatsbyFluidImage
                            }
                        }
                    }
                }
            }
        `
    )

    const { generalSpeakersPlaceholder } = data.wp.general.acf

    return (
        <div
            className={classnames("columns", styles.Speaker, {
                [styles.Speaker__Active]: active,
            })}
        >
            {
                <div className={styles.Speaker__Photo}>
                    {!photo && generalSpeakersPlaceholder && (
                        <GatsbyImage
                            image={
                                generalSpeakersPlaceholder.localFile
                                    .childImageSharp.gatsbyImageData
                            }
                            alt={name}
                        />
                    )}
                    {photo && (
                        <GatsbyImage
                            image={
                                photo.localFile.childImageSharp.gatsbyImageData
                            }
                            alt={name}
                        />
                    )}
                    {linkedinlink && (
                        <a
                            {...dataAttributes(
                                "section-eventspeakers-linkedin",
                                name
                            )}
                            href={linkedinlink}
                            target="_blank"
                            rel="noopener noreferrer nofollow"
                            className={styles.Speaker__Linkedin}
                        >
                            <img src={LinkedinIcon} alt="linkedin" />
                        </a>
                    )}
                </div>
            }
            <div className={styles.Speaker__Description}>
                <h2
                    className="h2--Small"
                    dangerouslySetInnerHTML={{ __html: name }}
                />
                <p
                    className={styles.Speaker__Position}
                    dangerouslySetInnerHTML={{ __html: position }}
                />
                {companyLogo && companyUrl && (
                    <a
                        {...dataAttributes("section-eventspeakers-url", name)}
                        href={companyUrl}
                        target="_blank"
                        rel="noopener noreferrer nofollow"
                    >
                        <GatsbyImage
                            className={styles.Speaker__Logo}
                            image={
                                companyLogo.localFile.childImageSharp
                                    .gatsbyImageData
                            }
                            alt={companyUrl}
                        />
                    </a>
                )}
            </div>
        </div>
    )
}

Speaker.propTypes = {
    name: PropTypes.string.isRequired,
    position: PropTypes.string,
    linkedinlink: PropTypes.string,
    companyUrl: PropTypes.string,
    companyLogo: PropTypes.array,
}

export default Speaker
